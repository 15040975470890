import {
  axiosAPI,
  axiosAuthAPI,
  axiosUploadAPI,
  axiosKeyServerAPI,
} from '@/plugins/axios/index';
import store from '@/store';

const baseURL = process.env.VUE_APP_ORIGIN || window.location.origin;

function configAxiosForWebConsole(teamName) {
  // setup axios for app api
  axiosAPI.defaults.withCredentials = true;
  axiosAPI.defaults.headers.common = { 'X-ByteArk-Team': teamName };

  axiosAPI.interceptors.response.use((response) => response, async (error) => {
    if (error.response.status === 401) {
      await store.dispatch('destroyCurrentUser');
      window.location.href = `${baseURL}/accounts/byteark`;
    }
    return Promise.reject(error);
  });

  // setup axios for upload api
  axiosUploadAPI.defaults.withCredentials = true;
  axiosUploadAPI.defaults.headers.common = { 'X-ByteArk-Team': teamName };

  axiosUploadAPI.interceptors.response.use((response) => response, async (error) => {
    if (error.response.status === 401) {
      await store.dispatch('destroyCurrentUser');
      window.location.href = `${baseURL}/accounts/byteark`;
    }
    return Promise.reject(error);
  });

  // setup axios for auth service api
  axiosAuthAPI.defaults.withCredentials = true;
  axiosAuthAPI.defaults.headers.common = { 'X-ByteArk-Team': teamName };

  axiosAuthAPI.interceptors.response.use((response) => response, async (error) => {
    if (error.response.status === 401) {
      await store.dispatch('destroyCurrentUser');
      window.location.href = `${baseURL}/accounts/byteark`;
    }

    return Promise.reject(error);
  });

  // setup axios for upload api
  axiosKeyServerAPI.defaults.withCredentials = true;
  axiosKeyServerAPI.defaults.headers.common = { 'X-ByteArk-Team': teamName };

  axiosKeyServerAPI.interceptors.response.use((response) => response, async (error) => {
    if (error.response.status === 401) {
      await store.dispatch('destroyCurrentUser');
      window.location.href = `${baseURL}/accounts/byteark`;
    }
    return Promise.reject(error);
  });
}

async function configAxiosForForms(formId, token) {
  // Setup Axios for SDK
  let accessToken;

  try {
    const response = await axiosAuthAPI.post(`/public/apps/${formId}/access-tokens`, { appToken: token });
    accessToken = response.data.accessToken;
    await store.dispatch('setFormAccessToken', accessToken);
  } catch (error) {
    store.dispatch('setFormError', error.response);
    accessToken = null;
  }

  // set axios's header for auth service api
  axiosAuthAPI.interceptors.request.use(
    async (config) => {
      const axiosConfig = config;
      axiosConfig.headers = {
        Authorization: `Bearer ${store.getters.formAccessToken}`,
      };
      return axiosConfig;
    }, (error) => Promise.reject(error),
  );

  // set axios's header for app api
  axiosAPI.interceptors.request.use(
    async (config) => {
      const axiosConfig = config;
      axiosConfig.headers = {
        Authorization: store.getters.formAccessToken,
      };
      return axiosConfig;
    }, (error) => Promise.reject(error),
  );
}

export {
  configAxiosForWebConsole,
  configAxiosForForms,
};
